// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---configs-infoli-about-page-index-js": () => import("./../../../configs/infoli/AboutPage/index.js" /* webpackChunkName: "component---configs-infoli-about-page-index-js" */),
  "component---configs-infoli-landing-page-index-js": () => import("./../../../configs/infoli/LandingPage/index.js" /* webpackChunkName: "component---configs-infoli-landing-page-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-maintenance-js": () => import("./../../../src/pages/maintenance.js" /* webpackChunkName: "component---src-pages-maintenance-js" */),
  "component---src-templates-account-manage-index-js": () => import("./../../../src/Templates/AccountManage/index.js" /* webpackChunkName: "component---src-templates-account-manage-index-js" */),
  "component---src-templates-auth-redirect-index-js": () => import("./../../../src/Templates/AuthRedirect/index.js" /* webpackChunkName: "component---src-templates-auth-redirect-index-js" */),
  "component---src-templates-blog-detail-index-js": () => import("./../../../src/Templates/BlogDetail/index.js" /* webpackChunkName: "component---src-templates-blog-detail-index-js" */),
  "component---src-templates-blog-list-blog-list-b-js": () => import("./../../../src/Templates/BlogList/BlogList.B.js" /* webpackChunkName: "component---src-templates-blog-list-blog-list-b-js" */),
  "component---src-templates-cart-index-js": () => import("./../../../src/Templates/Cart/index.js" /* webpackChunkName: "component---src-templates-cart-index-js" */),
  "component---src-templates-faq-index-js": () => import("./../../../src/Templates/Faq/index.js" /* webpackChunkName: "component---src-templates-faq-index-js" */),
  "component---src-templates-forget-password-index-js": () => import("./../../../src/Templates/ForgetPassword/index.js" /* webpackChunkName: "component---src-templates-forget-password-index-js" */),
  "component---src-templates-monthly-detail-index-js": () => import("./../../../src/Templates/MonthlyDetail/index.js" /* webpackChunkName: "component---src-templates-monthly-detail-index-js" */),
  "component---src-templates-monthly-manage-index-js": () => import("./../../../src/Templates/MonthlyManage/index.js" /* webpackChunkName: "component---src-templates-monthly-manage-index-js" */),
  "component---src-templates-order-detail-index-js": () => import("./../../../src/Templates/OrderDetail/index.js" /* webpackChunkName: "component---src-templates-order-detail-index-js" */),
  "component---src-templates-order-list-index-js": () => import("./../../../src/Templates/OrderList/index.js" /* webpackChunkName: "component---src-templates-order-list-index-js" */),
  "component---src-templates-product-detail-index-js": () => import("./../../../src/Templates/ProductDetail/index.js" /* webpackChunkName: "component---src-templates-product-detail-index-js" */),
  "component---src-templates-product-list-index-js": () => import("./../../../src/Templates/ProductList/index.js" /* webpackChunkName: "component---src-templates-product-list-index-js" */),
  "component---src-templates-profile-index-js": () => import("./../../../src/Templates/Profile/index.js" /* webpackChunkName: "component---src-templates-profile-index-js" */),
  "component---src-templates-register-index-js": () => import("./../../../src/Templates/Register/index.js" /* webpackChunkName: "component---src-templates-register-index-js" */),
  "component---src-templates-test-page-js": () => import("./../../../src/Templates/TestPage.js" /* webpackChunkName: "component---src-templates-test-page-js" */),
  "component---src-templates-validation-index-js": () => import("./../../../src/Templates/Validation/index.js" /* webpackChunkName: "component---src-templates-validation-index-js" */)
}

