import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import 'antd/dist/antd.less';
import '../../index.css';
import Toast from '../Toast';
import Footer from '../Footer';
import Modal from '../../Modals';
import Navbar from '../Navbar';
import Delighters from '../ReactDelighters';
import {Helmet} from 'react-helmet';
import styled from 'styled-components';
import ContactUsButton from '../ContactUsButton';
import {navigate, withPrefix} from 'gatsby';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {normalizePath} from '../../Utils/pathUtil';
const appConfig = require('../../data.json');

const Layout = ({location, children, seo}) => {
  const _pathname = normalizePath(location.pathname);
  const extraHeadMetaArray = appConfig['extra-head-meta'] || [];

  React.useLayoutEffect(() => {
    if (appConfig.maintenance && location.pathname !== '/maintenance/') {
      navigate('/maintenance/');
    }
  }, [location]);

  return (
    <main>
      <Helmet>
        {/* <link rel="shortcut icon" href="/favicon.ico" /> */}
        <title>{seo.title || appConfig.information.name}</title>

        {seo.keywords && <meta name="keywords" content={seo.keywords} />}

        {seo.title && <meta property="og:title" content={seo.title} />}
        {seo.description && (
          <meta name="description" content={seo.description} />
        )}
        {seo.image && <meta name="image" content={seo.image} />}
        {seo.description && (
          <meta property="og:description" content={seo.description} />
        )}
        {seo.image && <meta property="og:image" content={seo.image} />}

        {extraHeadMetaArray.map((m, idx) => (
          <meta key={idx} {...m} />
        ))}

        <meta name="author" content="EcultureTech 奕果雲端數位股份有限公司" />

        <script src={'/yahoo-ad-tracking.js'} type="application/javascript" />
      </Helmet>
      <Delighters>
        <Content>
          <Navbar pathname={_pathname} />
          <div
            style={{
              flex: 1,
              position: 'relative',
              minHeight: '70vh',
              display: 'flex',
              flexDirection: 'column',
            }}>
            {children}
          </div>
          <Footer />
        </Content>
      </Delighters>
      <Toast />
      <Modal />

      <ContactUsButton location={location} />
    </main>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

const Content = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export default Layout;
